<template>
  <div>
    <el-row>
<!--      <el-col :xs="24" :sm="14" class="detail">-->
      <el-col class="detail">
        <div class="hd">
<!--          <span>{{ $t('Credit Number') }}：{{ item.id }}</span>-->
          <span>{{ $t('Date') }}：{{ item.addedDate|formatDate('yyyy-MM-dd') }}</span>
        </div>
        <div class="bd cl">
          <div class="infos">{{ item.description }}</div>
        </div>
        <div class="bd cl" v-if="item.remarks">
          <div class="infos">{{$t('Remarks')}}: {{ item.remarks }}</div>
        </div>
        <div class="bd cl">
          <div v-if="displayCredit(item.value)" class="positiveValue" >+{{item.value}}</div>
          <div v-else class="negativeValue" >{{item.value}}</div>
        </div>
      </el-col>
    </el-row>

  </div>
</template>

<script>
export default {
  name: 'CreditListItem',
  props: {
    item: Object
  },
  components: {},
  created() {
    this.item.addDate = this.item.addDate + (8 * 60 * 60 * 1000);
  },
  mounted() {
  },
  methods: {
    displayCredit(value){
      return value > 0;
    }
  }
};
</script>

<style scoped lang="less">
.detail{
  padding-left: 20px;
}

.hd {
  line-height: 28px;
  border-bottom: 1px dashed #e0e0e0;

  span {
    display: inline-block;
    margin-right: 30px;
  }
}

.bd {
  margin-top: 8px;
}

.ft {
  margin-top: 15px;
  text-align: right;
  font-size: 0;
}

.infos {
  display: flex;
}

.positiveValue {
  float: right;
  font-size: large;
  color: #ff6f61;
}

.negativeValue {
  float: right;
  font-size: large;
  color: #000000;
}

.btn-primary,
.btn-gray {
  display: inline-block;
  margin-left: 10px;
  padding: 0 12px;
  font-size: 12px;
  border-radius: 4px;
  cursor: pointer;
  user-select: none;

  &:hover {
    opacity: 0.8;
  }
}

.btn-primary {
  line-height: 20px;
  color: #fff;
  background-color: #ff6f61;
}

.btn-gray {
  line-height: 18px;
  color: #321908;
  border: 1px solid #e6e6e6;
  background-color: #fff;
}

.fc1 {
  color: #ff6f61;
}
</style>
