<template>
  <div class="passenger-info">
    <template v-if="!isAddNew">
      <div class="cl">
        <a class="fl btn-add" @click="isAddNew=true">
          <img class="ico" src="../../../assets/images/icon/plus-circle.png"/>
          <span>{{$t('Add passengers')}}</span>
        </a>
      </div>
      <div class="passenger-list">
        <div class="item" v-for="(item,index) of list" :key="item.id">
          <div class="infos cl" v-if="!item.isEdit">
            <div class="fl nickname">{{item.chineseSurname}}{{item.chineseName}}</div>
            <div class="fl">{{item.englishSurname}} {{item.englishName}}</div>
            <div class="fr actions">
              <a class="btn-edit" href="javascript:;" @click="item.isEdit=!item.isEdit">
                <img src="../../../assets/images/icon/pen.png"/>
              </a>
              <a class="btn-del" href="javascript:;"
                 @click="deleteTraveler(index,item.travelerId,item.englishSurname,item.englishName)">
                <img src="../../../assets/images/icon/ashcan.png"/>
              </a>
            </div>
          </div>
          <transition name="slide">
            <edit-box :index="index"
                      :traveler-id="item.travelerId"
                      @cancel-editing="cancelEditing"
                      @update-item="updateItem"
                      v-if="item.isEdit"/>
          </transition>
        </div>
      </div>
      <el-pagination class="pages"
                     background
                     layout="prev, pager, next"
                     :page-count="totalPages"
                     @current-change="changePage"
                     hide-on-single-page>
      </el-pagination>
    </template>
    <edit-box :index="-1" @cancel-editing="cancelEditing" @update-item="updateItem" v-else/>
  </div>
</template>
<script>
  import EditBox from './EditBox';
  import {getTravelerList, deleteTraveler} from '../../../api/traveler';

  export default {
    name: 'PassengerInfo',
    data() {
      return {
        page: 1,
        list: [],
        isAddNew: false,
        totalPages: 0
      };
    },
    components: {EditBox},
    created() {
      this.getTravelerList();
    },
    methods: {
      // 取消编辑
      cancelEditing(index) {
        if (index === -1) {
          this.isAddNew = false;
        } else {
          this.list[index].isEdit = false;
        }
      },
      // 获取旅客列表
      getTravelerList() {
        const data = {page: this.page, perPage: 10};
        getTravelerList(data).then(res => {
          const {value} = res;
          this.list = (value.list || []).map(item => {
            item.isEdit = false;
            return item;
          });
          this.totalPages = value.totalPages;
        });
      },
      changePage(page) {
        this.page = page;
        this.getTravelerList();
      },
      updateItem(index, data) {
        if (index === -1) {
          this.page = 1;
          this.getTravelerList();
        } else {
          Object.assign(this.list[index], data);
        }
      },
      // 删除旅客
      deleteTraveler(index, travelerId, englishSurname, englishName) {
        this.$msgbox({
          title: this.$t('Prompt'),
          message: `确定要删除 [${englishSurname} ${englishName}] 吗`,
          showCancelButton: true,
          lockScroll: false,
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              instance.confirmButtonLoading = true;
              deleteTraveler(travelerId).then(res => {
                instance.confirmButtonLoading = false;
                done();
                this.$message.success('刪除成功!');
                this.list.splice(index, 1);
                if (!this.list.length) {
                  this.page = 1;
                  this.getTravelerList();
                }
              }).catch(() => {
                instance.confirmButtonLoading = false;
              });
            } else {
              done();
            }
          }
        }).then(() => {
        }).catch(() => {
        });
      }
    }
  };
</script>
<style scoped lang="less">
  .passenger-info{margin-top:20px;}
  .btn-add{
    display:block;padding:0 15px;line-height:44px;font-size:0;background-color:#ff6f61;border-radius:4px;
    .ico{vertical-align:middle;}
    span{display:inline-block;margin-left:12px;vertical-align:middle;color:#fff;font-size:18px;}
  }
  .passenger-list{
    margin-top:8px;
    .item{border-bottom:1px dashed #e0e0e0;}
    .infos{line-height:44px;}
    .nickname{margin-right:20px;}
    .actions{font-size:0;}
    .btn-edit,
    .btn-del{display:inline-block;height:16px;margin-left:24px;vertical-align:middle;}
  }
  .slide-enter-active{overflow:hidden;animation:slide .4s;}
  .slide-leave-active{
    overflow:hidden;animation:slide .4s reverse;
    /deep/ .actions{display:none;}
  }
  @keyframes slide{
    0%{height:0;}
    100%{height:658px;}
  }
</style>
