<template>
  <div>
    <div class="actions cl">
      <template v-if="index!==-1">
        <div class="fl nickname">{{chineseSurname}}{{chineseName}}</div>
        <div class="fl">{{englishSurname}} {{englishName}}</div>
      </template>
      <a class="fr btn-cancel" href="javascript:;" @click="cancelEditing">{{$t('Cancel')}}</a>
      <a class="fr btn-save" href="javascript:;" @click="onSubmit">{{$t('Save')}}</a>
    </div>
    <div class="edit-box">
      <!--稱謂-->
      <div class="form-item">
        <div class="label"><span class="required">*</span>{{$t('Appellation')}}</div>
        <div>
          <el-select class="select" size="small" :placeholder="$t('PleaseChoose')" v-model="isCalled">
            <el-option v-for="item in appellationOptions"
                       :key="item.id"
                       :label="item.name"
                       :value="item.code">
            </el-option>
          </el-select>
        </div>
      </div>
      <!--姓名(英文)-->
      <div class="form-item">
        <div class="label"><span class="required">*</span>{{$t('Name')}}({{$t('English')}})</div>
        <div>
          <input class="ipt m-r" type="text" autocomplete="off" placeholder="姓 Surname"
                 v-model.trim="englishSurname"/>
          <input class="ipt" type="text" autocomplete="off" placeholder="名 Given Name" v-model.trim="englishName"/>
        </div>
      </div>
      <!--姓名(中文)-->
      <div class="form-item">
        <div class="label">{{$t('Name')}}({{$t('Chinese')}})</div>
        <div>
          <input class="ipt m-r" type="text" autocomplete="off" placeholder="姓"
                 v-model.trim="chineseSurname"/>
          <input class="ipt" type="text" autocomplete="off" placeholder="名" v-model.trim="chineseName"/>
        </div>
      </div>
      <!--性別-->
      <div class="form-item">
        <div class="label"><span class="required">*</span>{{$t('Gender')}}</div>
        <div>
          <radio v-model="gender" label="M">{{$t('Male')}}</radio>
          <radio v-model="gender" label="F">{{$t('Female')}}</radio>
        </div>
      </div>
      <div class="cl">
        <i></i>
        <!--證件類型-->
        <div class="fl form-item m-r">
          <div class="label"><span class="required">*</span>{{$t('Certificate Type')}}</div>
          <div>
            <el-select class="select w1"
                       v-model="paperworkType"
                       size="small"
                       :placeholder="$t('PleaseChoose')"
                       @change="paperworkTypeChange">
              <el-option v-for="item in idcardTypeOptions"
                         :key="item.id"
                         :label="item.name"
                         :value="item.code">
              </el-option>
            </el-select>
          </div>
        </div>
        <!--其他證件-->
        <div class="fl form-item m-r" v-if="paperworkType==='qt'">
          <div class="label"><span class="required">*</span>{{$t('Other Certificate')}}</div>
          <div>
            <input class="ipt" type="text" autocomplete="off" :placeholder="$t('PleaseEnter')"
                   v-model.trim="otherPaperwork"/>
          </div>
        </div>
        <!--證件簽發地-->
        <div class="fl form-item">
          <div class="label"><span class="required">*</span>{{$t('Certificate of Issue')}}</div>
          <div>
            <el-select class="select w1" size="small" :placeholder="$t('PleaseChoose')"
                       v-model="issuingCountry"
                       :disabled="['CT3','CT6','CT7','CT5'].includes(paperworkType)">
              <el-option v-for="item in countryOptions"
                         :key="item.id"
                         :label="item.name"
                         :value="item.code">
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div class="cl">
        <i></i>
        <!--證件號碼-->
        <div class="fl form-item m-r">
          <div class="label"><span class="required">*</span>{{$t('Certificate Number')}}</div>
          <div>
            <input class="ipt" type="text" autocomplete="off" :placeholder="$t('PleaseEnter')" v-model.trim="idNumber"/>
          </div>
        </div>
        <!--證件有效期-->
        <div class="fl form-item m-r" v-if="paperworkType!=='sfz'">
          <div class="label"><span class="required">*</span>{{$t('Certificate Validity Period')}}</div>
          <div>
            <el-date-picker class="select"
                            v-model="effectiveDate"
                            type="date"
                            size="small"
                            :picker-options="effectiveDateOptions"
                            value-format="yyyy-MM-dd"
                            :placeholder="$t('PleaseChoose')">
            </el-date-picker>
          </div>
        </div>
        <!--出生日期-->
        <div class="form-item">
          <div class="label"><span class="required">*</span>{{$t('DateOfBirth')}}</div>
          <div>
            <el-date-picker class="select"
                            v-model="birthday"
                            type="date"
                            size="small"
                            :picker-options="birthdayOptions"
                            value-format="yyyy-MM-dd"
                            :placeholder="$t('PleaseChoose')">
            </el-date-picker>
          </div>
        </div>
      </div>
      <!--手提電話-->
      <div class="form-item">
        <div class="label">{{$t('Mobile Number')}}</div>
        <div class="cl">
          <el-select class="select w1 m-r" size="small" :placeholder="$t('Area code')" v-model="areaCode">
            <el-option v-for="item in areaCodeOptions"
                       :key="item.id"
                       :label="item.name"
                       :value="item.code">
            </el-option>
          </el-select>
          <input class="ipt" type="number" autocomplete="off" :placeholder="$t('Mobile Number')"
                 v-model.trim="userPhone"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import Radio from '../../../components/Radio';
  import {getDictList} from '../../../api/common';
  import {getTravelerDetail, saveTravelerInfo} from '../../../api/traveler';

  export default {
    name: 'EditBox',
    props: {
      index: Number,
      travelerId: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        appellationOptions: [],
        isCalled: '',
        birthday: '',
        birthdayOptions: {
          disabledDate(time) {
            return time.getTime() > Date.now();
          }
        },
        effectiveDateOptions: {
          disabledDate(time) {
            return time.getTime() < Date.now();
          }
        },
        gender: '',
        countryOptions: [],
        idcardTypeOptions: [],
        paperworkType: '',
        issuingCountry: '',
        effectiveDate: '',
        areaCodeOptions: [],
        areaCode: '',
        englishSurname: '',
        englishName: '',
        chineseSurname: '',
        chineseName: '',
        idNumber: '',
        userPhone: '',
        otherPaperwork: ''
      };
    },
    components: {Radio},
    created() {
      this.getDetail();
      this.getDicts();
    },
    methods: {
      // 获取字典
      getDicts(data = {}) {
        // 国家/证件签发地
        getDictList('country').then(res => {
          this.countryOptions = res.value || [];
        });

        // 证件类型
        getDictList('certificateType').then(res => {
          this.idcardTypeOptions = res.value || [];
        });

        // 手机区号
        getDictList('phonePrefix').then(res => {
          this.areaCodeOptions = res.value || [];
        });

        // 获取称谓选项
        getDictList('appellative').then(res => {
          this.appellationOptions = res.value || [];
        });
      },
      // 获取详情
      getDetail() {
        if (this.travelerId) {
          getTravelerDetail(this.travelerId).then(res => {
            const {value} = res;
            this.englishSurname = value.englishSurname;
            this.englishName = value.englishName;
            this.chineseSurname = value.chineseSurname;
            this.chineseName = value.chineseName;
            this.idNumber = value.idNumber;
            this.effectiveDate = value.effectiveDate;
            this.birthday = value.birthday;
            this.areaCode = value.phoneType;
            this.userPhone = value.userPhone;
            this.gender = value.genderType;
            this.issuingCountry = value.issuingCountry;
            this.paperworkType = value.paperworkType;
            this.isCalled = value.isCalled;
            this.otherPaperwork = value.otherPaperwork;
          });
        }
      },
      cancelEditing() {
        this.$emit('cancel-editing', this.index);
      },
      onSubmit() {
        const data = {
          travelerId: this.travelerId,
          englishSurname: this.englishSurname,
          englishName: this.englishName,
          chineseSurname: this.chineseSurname,
          chineseName: this.chineseName,
          genderType: this.gender,
          birthday: this.birthday,
          paperworkType: this.paperworkType,
          idNumber: this.idNumber,
          issuingCountry: this.issuingCountry,
          effectiveDate: this.effectiveDate,
          phoneType: this.areaCode,
          userPhone: this.userPhone,
          isCalled: this.isCalled,
          otherPaperwork: this.otherPaperwork
        };

        if (!data.isCalled || !data.englishSurname || !data.englishName || !data.genderType || !data.paperworkType || !data.idNumber || !data.issuingCountry || (!data.effectiveDate && this.paperworkType !== 'sfz')) {
          this.$message.warning('请完善旅客信息后再提交');
          return false;
        }
        saveTravelerInfo(data).then(res => {
          this.cancelEditing();
          this.$message.success(this.$t('SaveSuccessfully'));
          this.$emit('update-item', this.index, data);
        }).catch(() => {
        });
      },
      // 切换证件类型后证件签发证相关操作
      paperworkTypeChange(value) {
        switch (value) {
          // 香港簽證身份書
          case 'CT3':
            this.issuingCountry = '020511';
            break;
          // 回鄉證(CT6)、台胞證(CTtw、CT20)、港澳通行証(CT7)
          case 'CT6':
          // case 'CTtw':
          // case 'CT20':
          // eslint-disable-next-line no-fallthrough
          case 'CT7':
            this.issuingCountry = '020509';
            break;
          // 澳門旅行證(CT5)
          case 'CT5':
            this.issuingCountry = '020512';
            break;
          // 身份证
          case 'sfz':
            this.effectiveDate = '';
            break;
          default:
            this.issuingCountry = '';
            break;
        }
      }
    }
  };
</script>
<style scoped lang="less">
  .actions{
    line-height:44px;
    .nickname{margin-right:20px;}
    .btn-cancel{color:#999;}
    .btn-save{margin-right:40px;color:#ff6f61;}
  }
  .edit-box{
    padding:20px;background-color:#f4f4f4;
    .m-r{margin-right:8px;}
  }
  .form-item{
    margin-top:20px;
    &:first-child{margin-top:0;}
    .label{margin-bottom:8px;color:#321908;font-size:14px;}
    .ipt{
      width:180px;height:32px;padding:0 10px;color:#321908;font-size:14px;border:1px solid #d9d9d9;border-radius:4px;
      &:focus{border-color:#ff6f61;}
    }
    .select{width:180px;vertical-align:top;}
    .required{color:#f00;}
    /deep/ .radio .label{color:#321908;}
  }
</style>
