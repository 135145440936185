<template>
  <div class="main">
    <h1 class="h1">{{$t('MyCredit')}}: {{credit}}</h1>
    <div class="group order-info">
      <div class="hd">{{$t('Credit History')}}</div>
    </div>
    <div class="order-list">
      <div class="order-item" v-for="(item,index) of list" :key="item.creditID" style="cursor: pointer">
        <CreditListItem :item="item" :index="index" />
      </div>
    </div>
    <empty v-if="isEmpty" :title="$t('NoData')"/>
    <el-pagination class="pages"
                   background
                   layout="prev, pager, next"
                   :page-count="totalPages"
                   @current-change="changePage"
                   hide-on-single-page>
    </el-pagination>
  </div>
</template>
<script>
  import {getMyCredit} from '../../api/user';
  import CreditListItem from './components/CreditListItem';
  import Empty from '../../components/Empty';
  import {getCreditRecordList} from '../../api/credit';
  export default {
    name: 'MyCredit',
    data() {
      return {
        list:[],
        totalPages: 1,
        isEmpty: true,
        credit: 0
      };
    },
    computed: {},
    components: {Empty, CreditListItem},
    created() {
      this.getMyCredit();
      this.getCreditRecordList();
    },
    methods: {
      // 获取我的页面基础数据
      getMyCredit() {
        getMyCredit().then(res => {
          this.credit = res.value;
        });
      },
      getCreditRecordList(){
        getCreditRecordList().then(res => {
          this.list = res.value.list;
          if (this.list.length !== 0) {
            this.isEmpty = false;
          }
        });
      }
    }
  };
</script>
<style scoped lang="less">

.filters{
  margin:20px 0;
  .label{line-height:34px;}
}
.ipt-autocomplete{width:220px;}
.order-item{
  margin-bottom:8px;padding:20px;background-color:#f4f4f4;border-radius:4px;
}
@media only screen and (max-width: 768px) {
  .fr {
    margin-top: 10px;
  }
}

  .user-info{
    float:left;overflow:hidden;
    .avatar-uploader{float:left;}
    .myCredit{float:left;margin-left:30px;line-height:90px;color:#000;font-size:20px;}
    .btn-addName{margin:29px 0 0 30px;}
    /deep/ .avatar{width:90px;height:90px;}
  }
  .group{
    margin-top:30px;
    &.order-info{margin-top:60px;}
    .hd{line-height:40px;overflow:hidden;color:#000;font-size:20px;border-bottom:1px dashed #feb7b0;}
    .btn-more{float:right;}
  }
  .order-info{
    .link-item{
      float:left;width:21%;margin:18px 2% 0;text-align:center;
      &:hover{opacity:0.8;}
    }
    .label{margin-top:12px;color:#000;font-size:18px;}
    .value{color:#ff6f61;font-size:16px;}
  }
  .collection-item{width:210px;margin-top:10px;margin-bottom:0;}
  .my-travels{
    .item{
      display:block;float:left;width:210px;margin-top:10px;margin-right:15px;cursor:pointer;border-radius:4px;box-shadow:0 2px 9px 0 rgba(255, 115, 101, 0.2);
      &:nth-child(3n){margin-right:0;}
      .cover{position:relative;height:135px;margin:5px 5px 0;}
      .title{
        line-height:50px;text-align:center;
        span{
          display:inline-block;vertical-align:middle;
          &:first-child{max-width:80%;padding-right:5px;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;}
        }
      }
    }
  }
  .btn-discount{margin-top:23px;padding:0 23px;line-height:44px;color:#fff;font-size:18px;background-color:#ff6f61;border-radius:4px;}
</style>
