import request from '@/utils/request';

// 获取验证码
export function getCreditRecordList(data) {
  return request({
    url: '/credit/list',
    method: 'GET',
    data
  });
}
