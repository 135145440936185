<template>
  <div class="user-info">
    <div class="item">
      <div class="label">{{$t('Appellation')}}：</div>
      <div class="ipt-wrap">
        <el-select class="select" v-model="gender" size="small" :placeholder="$t('PleaseChoose')">
          <el-option v-for="item in genderOptions"
                     :key="item.id"
                     :label="item.name"
                     :value="item.code">
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="item">
      <div class="label">{{$t('Name')}}：</div>
      <div class="ipt-wrap">
        <input class="ipt" type="text" autocomplete="off" :placeholder="$t('PleaseEnter')" v-model.trim="userName"/>
      </div>
    </div>
    <div class="item">
      <div class="label">{{$t('Position')}}：</div>
      <div class="ipt-wrap">
        <input class="ipt" type="text" autocomplete="off" :placeholder="$t('PleaseEnter')" v-model.trim="position"/>
      </div>
    </div>
    <div class="item">
      <div class="label">{{$t('Phone')}}：</div>
      <div class="ipt-wrap cl">
        <div class="fl">
          <el-select class="select w1" v-model="areaCode" size="small" :placeholder="$t('Area code')">
            <el-option v-for="item in areaCodeOptions"
                       :key="item.code"
                       :label="item.name"
                       :value="item.code">
            </el-option>
          </el-select>
        </div>
        <div style="margin-left:130px;">
          <input class="ipt" type="text" autocomplete="off" :placeholder="$t('PleaseEnter')" v-model.trim="userPhone"/>
        </div>
      </div>
    </div>
    <div class="item">
      <div class="label">{{$t('Email')}}：</div>
      <div class="ipt-wrap">
        <input class="ipt" type="text" autocomplete="off" :placeholder="$t('PleaseEnter')" v-model.trim="userEmail"/>
      </div>
    </div>
    <a class="btn-submit" href="javascript:;" @click="onSubmit">{{$t('Edit')}}</a>
  </div>
</template>
<script>
  import {isEmail} from '../../../utils/validate';
  import {getDictList} from '../../../api/common';
  import {getGroupContactInfo, updateGroupContactInfo} from '../../../api/user';

  export default {
    name: 'GroupContactInformation',
    data() {
      return {
        genderOptions: [],
        gender: '',
        userName: '',
        position: '',
        userPhone: '',
        userEmail: '',
        areaCodeOptions: [],
        areaCode: ''
      };
    },
    created() {
      this.getDicts();
      this.getInfo();
    },
    methods: {
      // 获取数据字典
      getDicts() {
        getDictList('phonePrefix').then(res => {
          const {value} = res;
          this.areaCodeOptions = value || [];
        });
        // 获取称谓选项
        getDictList('appellative').then(res => {
          this.genderOptions = res.value || [];
        });
      },
      // 获取联络人信息
      getInfo() {
        getGroupContactInfo().then(res => {
          const value = res.value;
          if (value) {
            this.userName = value.userName;
            this.gender = value.isCalled;
            this.position = value.position;
            this.areaCode = value.phoneType;
            this.userPhone = value.userPhone;
            this.userEmail = value.userEmail;
          }
        });
      },
      // 修改联络人信息
      onSubmit() {
        const data = {
          isCalled: this.gender,
          userName: this.userName,
          position: this.position,
          phoneType: this.areaCode,
          userPhone: this.userPhone,
          userEmail: this.userEmail
        };

        if (Object.values(data).filter(item => !item).length) {
          this.$message.warning('请完善聯繫人資料');
          return false;
        }
        if (!isEmail(this.userEmail)) {
          this.$message.warning(this.$t('PleaseEnterTheCorrectEmail'));
          return false;
        }

        updateGroupContactInfo(data).then(res => {
          this.$message.success(this.$t('SaveSuccessfully'));
        });
      }
    }
  };
</script>
<style scoped lang="less">
  .user-info{
    margin-top:40px;
    .item{display:flex;align-items:center;width:380px;margin-bottom:40px;margin-right:60px;}
    .label{color:#321908;font-size:16px;}
    .ipt-wrap{flex:1;}
    .ipt{
      width:100%;height:32px;padding:0 10px;color:#321908;font-size:14px;border:1px solid #d9d9d9;border-radius:4px;
      &:focus{border-color:#ff6f61;}
      &.w1{width:64px;margin-right:8px;}
    }
    .select{
      width:100%;vertical-align:top;
      &.w1{width:120px;}
    }
  }
</style>
