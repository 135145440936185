<template>
  <div class="change-password">
    <div class="form-item cl">
      <div class="label">{{$t('Current password')}}：</div>
      <div class="fl">
        <input class="ipt" type="password" autocomplete="off" :placeholder="$t('PleaseEnter')" v-model.trim="oldPassword"/>
      </div>
    </div>
    <div class="form-item cl">
      <div class="label">{{$t('New password')}}：</div>
      <div class="fl">
        <input class="ipt" type="password" autocomplete="off" :placeholder="$t('PleaseEnter')" v-model.trim="password"/>
      </div>
    </div>
    <div class="form-item cl">
      <div class="label">{{$t('Confirm new password')}}：</div>
      <div class="fl">
        <input class="ipt" type="password" autocomplete="off" :placeholder="$t('PleaseEnter')" v-model.trim="confirmPassword"/>
      </div>
    </div>
    <a class="btn-submit" href="javascript:;" :disabled="disableSubmit" @click="onSubmit">{{$t('OK')}}</a>
  </div>
</template>
<script>
  import {updatePassword} from '../../../api/user';

  export default {
    name: 'ChangePassword',
    data() {
      return {
        oldPassword: '',
        password: '',
        confirmPassword: ''
      };
    },
    computed: {
      disableSubmit: function () {
        return !this.oldPassword || !this.password || !this.confirmPassword;
      }
    },
    methods: {
      onSubmit() {
        const data = {
          oldPassword: this.oldPassword,
          newPassword: this.password
        };

        if (data.newPassword.length < 6) {
          this.$message.warning('密码长度至少6位');
          return false;
        }
        if (data.newPassword !== this.confirmPassword) {
          this.$message.warning('两次输入的密码不一致');
          return false;
        }

        updatePassword(data).then(res => {
          this.$message.success('修改成功');
          Object.assign(this.$data, this.$options.data());
        }).catch(() => {
        });
      }
    }
  };
</script>
<style scoped lang="less">
  .change-password{
    margin-top:40px;
    a.btn-submit{margin-left:96px;}
  }
  .form-item{
    margin-bottom:40px;
    .label{float:left;min-width:96px;line-height:32px;text-align:right;color:#321908;font-size:16px;}
    .ipt{
      width:300px;height:32px;padding:0 10px;color:#321908;font-size:14px;border:1px solid #d9d9d9;border-radius:4px;
      &:focus{border-color:#ff6f61;}
      &.w1{width:64px;margin-right:8px;}
    }
  }
</style>
