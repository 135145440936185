<template>
  <div class="user-info">
    <div class="cl">
      <div class="item">
        <div class="label">{{$t('Group name')}}：</div>
        <div class="ipt-wrap">
          <input class="ipt" type="text" autocomplete="off" :placeholder="$t('PleaseEnter')" v-model.trim="groupName"/>
        </div>
      </div>
      <div class="item">
        <div class="label">{{$t('Address')}}：</div>
        <div class="ipt-wrap">
          <input class="ipt" type="text" autocomplete="off" :placeholder="$t('PleaseEnter')" v-model.trim="address"/>
        </div>
      </div>
      <div class="item">
        <div class="label">{{$t('Contact Number')}}：</div>
        <div class="ipt-wrap">
          <input class="ipt" type="text" autocomplete="off" :placeholder="$t('PleaseEnter')" v-model.trim="phone"/>
        </div>
      </div>
    </div>
    <a class="btn-submit" href="javascript:;" @click="onSubmit">{{$t('Edit')}}</a>
  </div>
</template>
<script>
  import {updateMyInfo} from '../../../api/user';

  export default {
    name: 'GroupInformation',
    data() {
      return {
        groupName: '',
        address: '',
        phone: ''
      };
    },
    created() {
      this.getMyInfo();
    },
    methods: {
      // 获取我的信息
      getMyInfo() {
        this.$store.dispatch('user/getInfo').then(res => {
          const {value} = res;
          this.groupName = value.groupName || '';
          this.address = value.address || '';
          this.phone = value.phone || '';
        });
      },
      // 修改我的信息
      onSubmit() {
        const data = {
          groupName: this.groupName,
          address: this.address,
          phone: this.phone
        };

        if (!data.groupName) {
          this.$message.warning('團體名稱不能为空');
          return false;
        }
        if (!data.address) {
          this.$message.warning('地址不能为空');
          return false;
        }
        if (!data.phone) {
          this.$message.warning('聯絡電話不能为空');
          return false;
        }
        updateMyInfo(data).then(res => {
          this.$store.dispatch('user/updateNickname', data.groupName);
          this.$message.success(this.$t('SaveSuccessfully'));
        }).catch(() => {
        });
      }
    }
  };
</script>
<style scoped lang="less">
  .user-info{
    margin-top:40px;
    .item{display:flex;align-items:center;float:left;width:380px;margin-bottom:40px;margin-right:60px;}
    .label{min-width:80px;text-align:right;color:#321908;font-size:16px;}
    .ipt-wrap{flex:1;}
    .ipt{
      width:100%;height:32px;padding:0 10px;color:#321908;font-size:14px;border:1px solid #d9d9d9;border-radius:4px;
      &:focus{border-color:#ff6f61;}
      &.w1{width:64px;margin-right:8px;}
    }
    a.btn-submit{margin-left:80px;}
  }
</style>
