<template>
  <div class="user-info">
    <div class="cl">
      <div class="item">
        <div class="label">{{$t('username')}}：</div>
        <div class="ipt-wrap">
          <input class="ipt" type="text" autocomplete="off" :placeholder="$t('PleaseEnter')" v-model.trim="nickName"/>
        </div>
      </div>
      <div class="item">
        <div class="label">{{$t('Name')}}：</div>
        <div class="ipt-wrap">
          <input class="ipt" type="text" autocomplete="off" :placeholder="$t('PleaseEnter')" v-model.trim="fullName"/>
        </div>
      </div>
      <div class="item">
        <div class="label">{{$t('Gender')}}：</div>
        <div class="ipt-wrap">
          <el-select class="select" v-model="gender" size="small" :placeholder="$t('PleaseChoose')">
            <el-option v-for="item in genderOptions"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="item">
        <div class="label">{{$t('Phone')}}：</div>
        <div class="ipt-wrap">
          <input class="ipt" type="text" pattern="\d*" autocomplete="off" minlength="8" maxlength="11" :placeholder="$t('PleaseEnter')" v-model.trim="phone"/>
        </div>
      </div>
      <div class="item">
        <div class="label">{{$t('hkid')}}：</div>
        <div class="ipt-wrap">
          <input v-if="!lockhkid" class="ipt" type="text" autocomplete="off" maxlength="8" :placeholder="$t('PleaseEnter')" v-model.trim="hkid"/>
          <input v-else class="ipt" type="text" autocomplete="off" maxlength="8" :placeholder="$t('PleaseEnter')" v-model.trim="hkid" disabled/>
        </div>
      </div>
      <div class="item">
        <div class="label">{{$t('Email')}}：</div>
        <div class="ipt-wrap">
          <input class="ipt" type="text" autocomplete="off" :placeholder="$t('PleaseEnter')" v-model.trim="email" disabled/>
        </div>
      </div>
      <div class="item">
        <div class="label">{{$t('DateOfBirth')}}：</div>
        <div class="ipt-wrap">
          <el-date-picker class="select"
                          v-model="birthday"
                          type="date"
                          size="small"
                          :picker-options="pickerOptions"
                          value-format="yyyy-MM-dd"
                          :placeholder="$t('PleaseChoose')">
          </el-date-picker>
        </div>
      </div>
      <div class="item">
        <div class="label">{{$t('Country')}}/{{$t('Region')}}：</div>
        <div class="ipt-wrap">
          <el-select class="select" v-model="country" size="small" :placeholder="$t('PleaseChoose')">
            <el-option v-for="item in countryOptions"
                       :key="item.id"
                       :label="item.name"
                       :value="item.code">
            </el-option>
          </el-select>
        </div>
      </div>
    </div>
    <a class="btn-submit" href="javascript:;" :disabled="disableSubmit" @click="onSubmit">{{$t('Save')}}</a>
  </div>
</template>
<script>
  import {isEmail} from '../../../utils/validate';
  import {getDictList} from '../../../api/common';
  import {updateMyInfo} from '../../../api/user';

  export default {
    name: 'PersonalInformation',
    data() {
      return {
        genderOptions: [
          {value: 'M', label: this.$t('Male')},
          {value: 'F', label: this.$t('Female')}
        ],
        gender: '',
        birthday: '',
        pickerOptions: {
          disabledDate(time) {
            return time.getTime() > Date.now();
          }
        },
        countryOptions: [],
        country: '',
        nickName: '',
        phone: '',
        email: '',
        hkid: '',
        originalData: null,
        lockhkid: false,
        fullName: ''
      };
    },
    computed: {
      disableSubmit: function () {
        const newDate = {
          nickName: this.nickName,
          phone: this.phone,
          email: this.email,
          birthday: this.birthday,
          country: this.country,
          gender: this.gender,
          hkid: this.hkid,
          fullName: this.fullName
        };
        return this.originalData ? JSON.stringify(newDate) === JSON.stringify(this.originalData) : false;
      }
    },
    created() {
      getDictList('country').then(res => {
        this.countryOptions = res.value || [];
      });
      this.getMyInfo();
    },
    methods: {
      // 获取我的信息
      getMyInfo() {
        this.$store.dispatch('user/getInfo').then(res => {
          const {value} = res;
          const originalData = {
            nickName: value.nickName || '',
            phone: value.phone || '',
            email: value.email || '',
            birthday: value.birthday || '',
            country: value.country || '',
            gender: value.genderType || '',
            hkid: value.hkid || '',
            fullName: value.fullName || ''
          };
          this.originalData = originalData;
          Object.assign(this.$data, originalData);
          if (this.hkid !== '' && this.hkid !== null) {
            this.lockhkid = true;
          }
        });
      },
      // 修改我的信息
      onSubmit() {
        const data = {
          nickName: this.nickName,
          genderType: this.gender,
          birthday: this.birthday,
          phone: this.phone,
          email: this.email,
          country: this.country,
          hkid: this.hkid,
          fullName: this.fullName
        };

        if (!data.nickName) {
          this.$message.warning('姓名不能为空');
          return false;
        }
        if (data.email && !isEmail(data.email)) {
          this.$message.warning(this.$t('PleaseEnterTheCorrectEmail'));
          return false;
        }
        updateMyInfo(data).then(res => {
          this.$store.dispatch('user/updateNickname', data.nickName);
          this.$message.success(this.$t('SaveSuccessfully'));
        }).catch(() => {
        });
      }
    }
  };
</script>
<style scoped lang="less">
  .user-info{
    margin-top:40px;
    .item{display:flex;align-items:center;float:left;width:270px;margin-bottom:40px;margin-right:60px;}
    .label{color:#321908;font-size:16px;}
    .ipt-wrap{flex:1;}
    .ipt{
      width:100%;height:32px;padding:0 10px;color:#321908;font-size:14px;border:1px solid #d9d9d9;border-radius:4px;
      &:focus{border-color:#ff6f61;}
      &.w1{width:64px;margin-right:8px;}
    }
    .select{width:100%;vertical-align:top;}
  }
</style>
