<template>
  <div class="main">
    <h1 class="h1">{{title}}</h1>
    <tabs v-model="activeName" @tab-click="handleTabClick">
      <tab-pane :label="title" name="personalInformation" v-if="userType==='personal'">
        <personal-information/>
      </tab-pane>
      <template v-if="userType==='group'">
        <tab-pane :label="title" name="personalInformation">
          <group-information/>
        </tab-pane>
        <tab-pane :label="$t('ContactInformation')" name="GroupContactInformation">
          <group-contact-information/>
        </tab-pane>
      </template>
      <tab-pane :label="$t('ChangePassword')" name="changePassword">
        <change-password/>
      </tab-pane>
      <!--用户账号绑定，先隐藏起来-->
<!--      <tab-pane :label="$t('BindAccount')" name="bindAccount" v-if="userType==='personal'">-->
<!--        <bind-account/>-->
<!--      </tab-pane>-->
      <tab-pane :label="$t('CommonPassengerInformation')" name="passengerInfo">
        <passenger-info/>
      </tab-pane>
    </tabs>
  </div>
</template>
<script>
  import {mapGetters} from 'vuex';
  import Tabs from '../../components/Tabs/Tabs';
  import TabPane from '../../components/Tabs/TabPane';
  import PersonalInformation from './components/PersonalInformation';
  import ChangePassword from './components/ChangePassword';
  // import BindAccount from './components/BindAccount';
  import PassengerInfo from './components/PassengerInfo';
  import GroupInformation from './components/GroupInformation';
  import GroupContactInformation from './components/GroupContactInformation';

  export default {
    name: 'MyProfile',
    data() {
      return {
        activeName: ''
      };
    },
    computed: {
      ...mapGetters(['userType']),
      title: function () {
        return this.userType === 'group' ? this.$t('GroupInformation') : this.$t('personalInformation');
      }
    },
    components: {
      GroupContactInformation,
      GroupInformation,
      Tabs,
      TabPane,
      PersonalInformation,
      ChangePassword,
      // BindAccount,
      PassengerInfo
    },
    mounted() {
      this.activeName = this.$route.query.activeName || 'personalInformation';
    },
    methods: {
      handleTabClick(activeName) {
        this.$router.replace({name: 'MyProfile', query: {activeName}});
      }
    }
  };
</script>
<style scoped lang="less">
  .main{
    /deep/ .btn-submit{display:block;width:120px;margin-left:48px;line-height:44px;text-align:center;color:#fff;font-size:18px;background-color:#ff6f61;border-radius:4px;}
  }
</style>
